import * as React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// yarn add react-slick --dev --peer

// images
import HomeSlideA from "./slideA"
import HomeSlideB from "./slideB"
import HomeSlideC from "./slideC"
import HomeSlideD from "./slideD"

export default function SimpleSlider() {
  let settings = {
    dots: false,
    lazyLoad: false,
    adaptiveHeight: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 350,
    autoplaySpeed: 4000,
    cssEase: "linear",
    className: "slickSlider_container",
  }
  return (
    <>
      <Slider {...settings}>
        <div className="slick_itemBox">
          <HomeSlideB />
        </div>
        <div className="slick_itemBox">
          <HomeSlideA />
        </div>
        <div className="slick_itemBox">
          <HomeSlideC />
        </div>
        <div className="slick_itemBox">
          <HomeSlideD />
        </div>
      </Slider>
    </>
  )
}
