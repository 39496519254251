import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { AsideInfo } from "../components/aside/aside"
import SimpleSlider from "../components/slider/slider"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description="Fresh, fast Greek Food"
      canonical="https://papagreek.com"
    />

    <SimpleSlider />
    <div className="contentWidth grid">
      <div className="gridA">
        <h1 className="heading">Papa Greek</h1>
        <h2 className="subheading">
          Fast, fresh Greek food without the fast food experience.
        </h2>
        <p>
          For over 25 years we have been preparing traditional Greek Food. We
          cook everything traditionally, right down to the traditional Greek
          spices we use in our food. We do our best to source fresh, local
          ingredients whenever possible, such as our farm fresh chicken from
          Abbotsford, B.C. And all of our food is prepared and cooked daily in
          traditional Greek fashion.
        </p>
        <p>
          Your food will take less than 10 minutes to prepare. We encourage you
          to sit down, relax and enjoy your meal in one of our booths if you
          have the time. But if you don’t, please feel free to call our Burnaby
          Location at (604) 451-4060, or at our Maple Ridge location at (604)
          466-4060 and we can make sure your order is ready for pick-up.
        </p>
        <h2>Gluten &amp; Carb-Free Options Available</h2>
        Nearly everything on our menu is Gluten Free with Pita Bread and
        Spanakopita (Spinach Pie) being the only exceptions. We do accommodate
        food allergies but please make sure to tell us prior to ordering your
        meal.
        <h2>Catering options are available.</h2>
      </div>

      <div className="gridB">
        <AsideInfo />
      </div>
    </div>
  </Layout>
)

export default IndexPage
