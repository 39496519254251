import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HomeSlideD = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "heros/home/homeHero_slideD.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            formats: AUTO
          )
          resize(fit: INSIDE) {
            tracedSVG
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="slick_wrapper">
        <GatsbyImage
          className="slick_image"
          image={data.file.childImageSharp.gatsbyImageData}
        />
        <div className="slick_contentWrapper">
          <h2 className="slick_title">Carb &amp; Gluten Free!</h2>
        </div>
      </div>
    </>
  )
}

export default HomeSlideD
